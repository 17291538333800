import * as React from "react"

import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import { Box } from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'

import { styled } from '@mui/material/styles';

const PREFIX = 'AboutSection';
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    background:"rgb(245 243 243)", 
    borderBottom: "1px solid #ededed"
  },
  [`& .${classes.button}`]: {
    fontWeight: "600",
    // color: theme.palette.primary.main,
  },
}))

export default function HomeAboutSection() {
    return (
        <Root id="about" className={classes.root} >
            <Container maxWidth='md' align="center">
                <Box paddingTop={3} paddingBottom={3}> 
                    <Grid container justifyContent="center" spacing={1} alignItems="center">
                        <Grid item  >
                            <Typography variant="h6"> ITS è un'azienda di telecomunicazioni scelta negli anni da più di <strong>trecento clienti</strong> per la realizzazione di impianti di telecomunicazioni, videosorveglianza e sistemi antintrusione – anche a <strong> noleggio </strong></Typography>
                            <Box marginTop={2} align={"center"}> <Button href="about" color="primary" variant="outlined" className={classes.button} startIcon={<InfoIcon />}> maggiori informazioni </Button> </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Root>
    )
}

