import React from 'react'

import { Container, Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'


import { Link } from 'gatsby'
import clients from "../contents/clients.json"


import { styled } from '@mui/material/styles';

const PREFIX = 'HomeClients';
const classes = {
  root: `${PREFIX}-root`,
  gridItem: `${PREFIX}-gridItem`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: "#ffffff",
    boxShadow: "0 0 30px 0 rgb(0 0 0 / 3%)",
    borderTop: "1px solid #e9e9e9",
  },
  [`& .${classes.gridItem}`]: {
    margin: "auto",
  },
}))

// filter: "drop-shadow(0px 0px 10px rgba(0,0,0,0.2))"

export default function HomeClients() {

    return (
        <Root className={classes.root}>
            <Box padding={5} textAlign="center">
                <Container maxWidth='md'>
                    <Typography style={{ fontWeight: "600" , marginBottom: "1.25em"}} color="primary" variant="h5">Clienti & Partner</Typography>
                    {/* <Typography style={{ marginBottom: "1.5em"}} variant="h6">Collaboriamo con le migliori aziende di telecomunicazioni in Italia</Typography> */}
                    <Grid container alignContent="center" alignItems="center" spacing={ {lg: 1 , md: 2, sm: 2, xs: 5}} columns={{ xs: 3, sm:5, md: 6 }}>
                        {clients.sort((a,b) => a.order - b.order).map( (client) => (
                            <Grid item className={classes.gridItem} md={2} sm={2} xs={4}> <Link href={client.url}> <img style={{width:"70%", maxWidth:"160px", }} src={client.img} alt={client.name} /> </Link> </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Root>
    )
}

