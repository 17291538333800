import * as React from "react"

import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import Hero from "../components/Hero"
import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import HomeServicesSection from "../components/HomeServicesSection"
import HomeAboutSection from "../components/HomeAboutSection"
import HomeClients from "../components/HomeClients"

import lightTheme from "../themes/light"
import imageHero from "../images/hero.jpeg"

import InfoIcon from '@mui/icons-material/Info'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const IndexPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS Telecomunicazioni - Impianti Tecnologici e Speciali</title>
      <CssBaseline />
      <TopBar  />
      <Hero 
        title="Impianti Tecnologici e Speciali"
        description="Siamo una delle pochissime imprese sul mercato che possono vantare una **esperienza trentennale** nelle telecomunicazioni."
        image={imageHero}
        href="about"
        buttonCaption="scopri chi siamo"
        buttonIcon={<ArrowForwardIcon />}
        />
      { /* <HomeAboutSection /> */}
      <HomeServicesSection />
      <HomeClients />
      <Footer />
    </ThemeProvider>
  )
}

export default IndexPage
