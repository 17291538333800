import * as React from "react"

import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'

import HomeTextContainer from "./HomeTextContainer"

import ofIcon from "../images/optical-fiber.png"
import prIcon from "../images/ponti-radio.png"
import cctvIcon from "../images/cctv.png"

import { StaticImage } from "gatsby-plugin-image"

import { styled } from '@mui/material/styles';

const PREFIX = 'HomeServices';
const classes = {
  root: `${PREFIX}-root`,
  headerImg: `${PREFIX}-headerImg`,
  container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },

  [`& .${classes.headerImg}`]: {
    opacity: "0.75",
    animation: "spin 5s infinite linear",
    maxWidth: "50px",
    // filter: "drop-shadow(-2px -2px 0px #e91e63) drop-shadow(2px 2px 0 cyan)",
  },
  "@keyframes spin": {
    "0%" : {
        transform: "rotate(0deg)",
        filter: "drop-shadow(2px 2px 0 cyan) hue-rotate(0deg)"
    },
    "50%" : {
        filter: "drop-shadow(2px 2px 0px #e91e63) hue-rotate(180deg)"
    },
    "100%" : {
        transform: "rotate(360deg)",
        filter: "drop-shadow(2px 2px 0px cyan) hue-rotate(360deg)"
    },
  },  
}))


export default function HomeServicesSection() {
    return (
        <Root className={classes.root}>
            <Container id="servizi"  maxWidth='md' align="center">
                <Box paddingTop={5} paddingBottom={1}> 
                    <Grid container justifyContent="center" spacing={10} alignItems="center">
                        <Grid item xs={'auto'} sx={{ display: { sm: 'none', md: 'block', xs: 'none', lg: 'block'} }} >
                            <Box><StaticImage  placeholder="none" loading="eager" className={classes.headerImg} src="../images/optical-fiber.png" alt="of" objectFit="contain"/></Box>
                        </Grid>
                        <Grid item xs={'auto'} sx={{ display: { sm: 'none', md: 'block', xs: 'none', lg: 'block'} }} >
                            <Box><StaticImage placeholder="none" loading="eager" className={classes.headerImg} src="../images/ponti-radio.png" alt="of" objectFit="contain" /></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Typography style={{ fontWeight: "600" }} variant="h5" color="primary">{ "Cosa facciamo in ITS" }</Typography>
                        </Grid>
                        <Grid item xs={'auto'} sx={{ display: { sm: 'none', md: 'block', xs: 'none', lg: 'block'} }} >
                            <Box><StaticImage placeholder="none" loading="eager" className={classes.headerImg} src="../images/cctv.png" alt="of" objectFit="contain" /></Box>
                        </Grid>
                    
                        <Grid item xs={'auto'} sx={{ display: { sm: 'none', md: 'block', xs: 'none', lg: 'block'} }} >
                            <Box><StaticImage placeholder="none" loading="eager" className={classes.headerImg} src="../images/alarm.png" alt="of" objectFit="contain" /></Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <HomeTextContainer 
            title="I nostri servizi //" 
            subtitle="fibra ottica" 
            icon={ofIcon}
            hrefBtn="fibra"
            description={`Esperienza iniziata nei primi *anni 80* con la costruzione delle prime dorsali interurbane in Fibra Ottica nelle varie regioni italiane, continuata con i primi progetti di cablaggio da parte di Telecom Italia “SOCRATE 1 e 2” sino ai giorni nostri con lavori **FTTC**, **FTTH** e **delivery**, che ci vedono impegnati a vario titolo dal 2016. Aperti a collaborare B2B con altre imprese di telecomunicazioni.`}/>

            <HomeTextContainer 
            title="I nostri servizi //" 
            subtitle="ponti radio" 
            icon={prIcon}
            // direction="row-reverse"
            hrefBtn="pontiradio"
            description={`Possiamo installare collegamenti ADSL-HDSL siano questi economici o estrememante professionali e performanti a Banda davvero larga, a prezzi davvero concorrenziali.`}/>

            <HomeTextContainer 
            pb={5} 
            title="I nostri servizi //" 
            subtitle="videosorveglianza" 
            icon={cctvIcon}
            hrefBtn="videosorveglianza"
            description={`Impianti professionali di videosorveglianza, sia cablata che via radio.`}
            noBorder/>
        </Root>
    )
}

